.error-result{
    h3{
        margin-bottom: 15px;
        font-size: 24px;
        line-height: 1em;
    }

    p{
        font-size: 16px;
        line-height: 1.5em;
    }
}
