@import 'scss/base/variables';
@import 'scss/base/mixins';
@import 'scss/vendors/include-media';




.rule-email-form {

    .form-item-switch {

        .ant-row {
            justify-content: space-between;
            width: 250px;

            .ant-form-item-control {
                width: auto !important;
            }
        }
    }
}