@import 'scss/vendors/include-media';
@import 'scss/base/variables';

.guest-app {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $primary-color;
    background-image: linear-gradient(160deg, $primary-color, #000003);
}