.sub-page-collection {
    .avatar-box {

        .ant-avatar-icon {
            display: flex;
            align-items: center;
            font-size: 30px;
            justify-content: center;
        }
    }
}