.custom-skeleton {
    position: relative;
    z-index: 0;
    overflow: hidden;
    background: transparent;
    height: 16px;
    border-radius: 6px;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: -250%;
        bottom: 0;
        left: -250%;
        background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
        animation: ant-skeleton-loading 1.4s ease infinite;
    }
}

.my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
}

.my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
}

.my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}

.my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
}