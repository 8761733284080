@import 'scss/base/helpers';


a.ant-btn,
.ant-btn {
  min-width: 130px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  padding: 6px 15px !important;

  @include media('<phone') {
    min-width: 90px;
  }

  @include media('<tablet') {
    font-size: 12px;
    padding: 4px 10px;
  }

  >span {
    @extend .ellipsis;
    max-width: 100%;
  }

  .anticon {
    font-size: 21px;

    @include media('<phone') {
      font-size: 16px;
    }

    @include media('<tablet') {
      font-size: 14px;
    }

    &.anticon-plus {
      vertical-align: middle;
      margin-top: -3px;
      margin-left: -2px;
      margin-right: 4px;
      font-size: 16px;
    }
  }

  .arrow-down {
    transition: transform 0.3s;
    margin-right: -6px;
  }
}


.ant-btn-sm {
  line-height: 1.3;
  align-items: center;
  min-width: 90px !important;
  font-size: 12px;
}


.btn-add {
  display: inline-block;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  color: $primary-color;
  transition: color 0.3s;
  margin-bottom: 13px;

  &:hover {
    color: lighten($primary-color, 5%);
  }
}