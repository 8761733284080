@import 'scss/base/variables';
@import 'scss/vendors/include-media';


.top-bar {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 12px 15px 0 0;
  margin-bottom: 16px;

  @include media('>=tablet') {
    padding: 12px 30px 0 0;
  }

  @include media('>=1200px') {
    padding: 20px 58px 0;
  }
}