@import 'scss/base/variables';
@import 'scss/base/mixins';
@import 'scss/vendors/include-media';




.counter-form {

    .form-item-switch {

        .ant-row {
            justify-content: space-between;

            .ant-form-item-control {
                width: auto !important;
            }
        }
    }
}

.counters-list {
    display: grid;
    grid-gap: 60px;

    @include media('>=1600px') {
        grid-template-columns: repeat(2, 1fr);
    }


    >li {
        margin-bottom: 40px;

        .counter-title {
            display: block;
            font-size: 26px;
        }

        .form-item-switch {

            @include media('>=tablet') {
                margin-right: 30px;
            }
        }
    }

    .update-box {

        @include media('>=phone') {
            min-width: 250px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        @include media('>=tablet') {
            min-width: 300px;
        }


        .ant-btn {
            @include media('<phone') {
                width: 100%;
            }
        }
    }
}