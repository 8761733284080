.multi-select {

  .search-input {
    margin-bottom: 16px;
  }

  .ant-checkbox-wrapper {
    font-size: inherit;
  }
}


.holder-group-select {
  position: relative;
  height: 267px;
  margin-bottom: 12px;

  .loader-item {
    display: flex;
    justify-content: center;
  }

  .line-top,
  .line-bottom {
    position: absolute;
    left: -23px;
    right: -23px;
    height: 1px;
    margin: 0;
    background: rgba(34, 43, 51, 0.15);
  }

  .line-top {
    top: 0;
  }

  .line-bottom {
    bottom: 0;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #D8D8D8;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: darken(#D8D8D8, 10%);
  }
}