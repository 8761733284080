@import 'scss/base/variables';
@import 'scss/base/mixins';
@import 'scss/vendors/include-media';


.tag-box {

  .ant-tag {
    margin-bottom: 8px;
  }

  .site-tag-plus,
  .ant-input {
    display: flex;
    align-items: center;
    width: 100%;
    height: fit-content;
    align-self: flex-end;
    background-color: $lighten-gray;
    border-radius: 6px;
    border-width: 2px;
    height: 34px;
    border: transparent;
    padding: 0 11px;
    margin-bottom: 20px;
  }

  .anticon-plus {
    margin-right: 5px;
  }
}