@import 'scss/base/variables';
@import 'scss/vendors/include-media';


.holder-importing {
  text-align: center;

  .folder {
    margin-left: auto;
    margin-right: auto;
    margin-top: 35px;
    margin-bottom: 30px;
  }
}


.folder {
  display: flex;
  background-color: #D3D5D7;
  position: relative;
  width: 92px;
  height: 64px;
  margin-top: 25px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;

  .folder-tab {
    position: absolute;
    height: 10px;
    left: 0;
    bottom: 100%;
    display: block;
    width: 40%;
    border-top-left-radius: 8px;
    background-color: inherit;

    &:after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: calc(100% - 10px);
      border-bottom: 10px solid #D3D5D7;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
    }
  }


  .folder-icn {
    padding-top: 12px;
    width: 100%;
    height: 100%;
    display: block;
  }

  .downloading {
    width: 30px;
    height: 32px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    transform: rotate(180deg);

    .arrow-down & {
      transform: rotate(0) translateX(2px);
    }
  }

  .custom-arrow {
    width: 12px;
    height: 14px;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -7px;
    background-color: rgba(51, 61, 71, 0.4);
    -webkit-animation-name: downloading;
    -webkit-animation-duration: 1.5s;
    -webkit-animation-iteration-count: infinite;
    animation-name: downloading;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;

    &:after {
      content: '';
      position: absolute;
      display: block;
      top: 100%;
      left: -6px;
      border-top: 13px solid rgba(51, 61, 71, 0.4);
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
    }
  }

  .bar {
    width: 24px;
    height: 3px;
    transform: translateX(1px);
    background-color: rgba(51, 61, 71, 0.4);
    margin: 0 auto;
  }

  @-webkit-keyframes downloading {
    0% {
      top: 0;
      opacity: 1;
    }

    50% {
      top: 110%;
      opacity: 0;
    }

    52% {
      top: -110%;
      opacity: 0;
    }

    100% {
      top: 0;
      opacity: 1;
    }
  }

  @keyframes downloading {
    0% {
      top: 0;
      opacity: 1;
    }

    50% {
      top: 110%;
      opacity: 0;
    }

    52% {
      top: -110%;
      opacity: 0;
    }

    100% {
      top: 0;
      opacity: 1;
    }
  }
}

.modal-table {
  margin-bottom: 30px;

  .primary-color {
    text-transform: uppercase;
    color: $primary-color;
  }

  th {
    font-size: 12px;
    color: rgba(34, 43, 51, 0.6) !important;
  }

  td {
    color: #222B33;
  }

  th,
  td {
    background: #F2F3F3 !important;
    border-bottom-color: rgba(34, 43, 51, 0.15) !important;
  }


}