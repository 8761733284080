@import 'scss/base/variables';



.sider {
  position: relative;
  border-right: 1px solid $light-gray;
  padding-bottom: 60px;
}

.sider-logout {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -30px;
  font-size: 16px;
  color: $white;
  // font-weight: 600;
  display: flex;
  cursor: pointer;
  margin-top: auto;
  padding: 0 16px 0 35px;
  overflow: auto;

  &:hover {
    color: $primary-color;
  }

  span {
    display: flex;
    align-items: center;

    transition: color 0.3s;
  }

  .logout-icon {
    margin-right: 18px;
    font-size: 28px;
    transition: none;
  }
}

.content-holder {
  overflow: auto;
}